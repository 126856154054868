import React from 'react';
import styled from 'styled-components';
import { PlaceholderLoader } from '@hotelplan/components.common.placeholder-loader';
import { useDeviceType } from '@hotelplan/libs.context.device-type';

interface ITabsSkeletonProps {
  height?: number;
  maxTabs?: number;
}

interface ISearchInputsSkeletonProps {
  maxInputs?: number;
}

const TabsSkeleton: React.FC<ITabsSkeletonProps> = ({
  height = 82,
  maxTabs = 5,
}) => {
  const { mobile } = useDeviceType();

  return (
    <>
      {!mobile && (
        <PlaceholderLoader
          uid="tabsSkeletonSkeleton"
          height={height - 2}
          width={1170}
          style={{ width: '100%', height, display: 'block' }}
        >
          {[
            <React.Fragment key={0}>
              <rect x="38" y="24" width="158" height="25" />
              <rect x="232" width="1" height="80" />
            </React.Fragment>,
            <React.Fragment key={1}>
              <rect x="271" y="24" width="158" height="25" />
              <rect x="467" width="1" height="80" />
            </React.Fragment>,
            <React.Fragment key={2}>
              <rect x="506" y="24" width="158" height="25" />
              <rect x="700" width="1" height="80" />
            </React.Fragment>,
            <React.Fragment key={3}>
              <rect x="739" y="24" width="158" height="25" />
              <rect x="934" width="1" height="80" />
            </React.Fragment>,
            <rect key={4} x="974" y="24" width="158" height="25" />,
          ].slice(0, maxTabs)}
          <rect y="79" width="1170" height="1" />
        </PlaceholderLoader>
      )}
    </>
  );
};

export const SearchInputsSkeleton: React.FC<ISearchInputsSkeletonProps> = ({
  maxInputs = 3,
}) => {
  const { mobile } = useDeviceType();

  return (
    <>
      {!mobile && (
        <PlaceholderLoader
          uid="searchInputsSkeleton"
          height={100}
          width={1170}
          style={{ width: '100%', height: '100%', display: 'block' }}
        >
          {maxInputs === 3 && (
            <>
              <rect x="31" y="20" width="311" height="60" />
              <rect x="354" y="20" width="311" height="60" />
              <rect x="677" y="20" width="311" height="60" />
            </>
          )}
          {maxInputs === 2 && (
            <>
              <rect x="31" y="20" width="467" height="60" />
              <rect x="515" y="20" width="467" height="60" />
            </>
          )}

          <rect x="1000" y="20" width="140" height="60" rx="5" />
        </PlaceholderLoader>
      )}
    </>
  );
};

interface ISearchControlSkeletonProps
  extends ITabsSkeletonProps,
    ISearchInputsSkeletonProps {
  className?: string;
  testId?: string;
}

export const SearchControlInputsMobileSkeleton: React.FC = () => (
  <PlaceholderLoader
    uid="currentSearchSkeleton"
    width={375}
    height={258}
    style={{ width: '100%', height: '100%' }}
  >
    <rect x="24" y="21" width="326" height="45" />
    <rect x="24" y="74" width="326" height="45" />
    <rect x="24" y="127" width="326" height="45" />
    <rect x="24" y="188" width="326" height="52" rx="4" />
  </PlaceholderLoader>
);

export const SearchControlMobileSkeleton: React.FC = props => (
  <PlaceholderLoader
    uid="currentSearchSkeleton"
    height={328}
    width={375}
    style={{ width: '100%', height: '100%' }}
    {...props}
  >
    <rect x="24" y="42" width="62" height="14" rx="7" />
    <rect x="133" y="42" width="62" height="14" rx="7" />
    <rect x="244" y="42" width="62" height="14" rx="7" />
    <rect x="338" y="42" width="29" height="14" rx="7" />
    <circle cx="164" cy="27" r="10" />
    <circle cx="275" cy="27" r="10" />
    <circle cx="352" cy="25" r="10" />
    <circle cx="44" cy="27" r="10" />
    <circle cx="67" cy="27" r="10" />
    <rect x="24" y="91" width="326" height="45" />
    <rect x="24" y="144" width="326" height="45" />
    <rect x="24" y="197" width="326" height="45" />
    <rect x="24" y="258" width="326" height="52" rx="4" />
    <rect width="375" height="0.96401" transform="matrix(1 0 0 -1 0 1)" />
    <rect width="375" height="0.96401" transform="matrix(1 0 0 -1 0 70)" />
    <rect width="375" height="0.96401" transform="matrix(1 0 0 -1 1 328)" />
    <rect width="1.01449" height="70" transform="matrix(-1 0 0 1 110 0)" />
    <rect width="1" height="69" transform="matrix(-1 0 0 1 219 0)" />
    <rect width="1" height="69" transform="matrix(-1 0 0 1 329 0)" />
  </PlaceholderLoader>
);

const SkeletonWhiteWrap = styled.div({
  backgroundColor: 'white',
});

export const SearchControlSkeleton: React.FC<ISearchControlSkeletonProps> = ({
  className,
  maxInputs,
  testId = `sc-loading`,
  ...tabsProps
}) => {
  const { mobile } = useDeviceType();

  return mobile ? (
    <SearchControlMobileSkeleton data-id={testId} />
  ) : (
    <SkeletonWhiteWrap className={className} data-id={testId}>
      <TabsSkeleton {...tabsProps} />
      <SearchInputsSkeleton maxInputs={maxInputs} />
    </SkeletonWhiteWrap>
  );
};

export const SearchControlInputsSkeleton: React.FC<{
  className?: string;
}> = ({ className }) => {
  const { mobile } = useDeviceType();

  return (
    <>
      {mobile && <SearchControlInputsMobileSkeleton />}
      {!mobile && (
        <SkeletonWhiteWrap className={className}>
          <SearchInputsSkeleton />
        </SkeletonWhiteWrap>
      )}
    </>
  );
};
