import { useTranslation } from 'next-i18next';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { useAuthentication } from '@hotelplan/components.common.auth';
import { RadioButton } from '@hotelplan/components.common.buttons';
import { Icon } from '@hotelplan/components.common.icon';
import { Link } from '@hotelplan/components.common.link';
import { RouterLink } from '@hotelplan/components.common.router-link';
import { TabControls } from '@hotelplan/components.common.tabs';
import { TravelType } from '@hotelplan/graphql.types';
import { useOnClickOutside } from '@hotelplan/libs.hooks-dom';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { ENTER_KEY } from 'components/domain/keyboard-navigation/keyboard-navigation.constant';
import { ExtraTravelType } from './TravelType.constants';
import { mapTravelTypeToTravelTypeData } from './TravelTypes.mappers';

const DropDownMobileWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'background',
    position: 'absolute',
    top: '100%',
    right: 0,
    boxShadow: 'large',
    zIndex: 1,
  })
);

export function getLangSpecificLink(
  language: string,
  linkConfig?: string | { [lang: string]: string }
): string {
  return typeof linkConfig === 'object'
    ? linkConfig[language]
    : linkConfig || '';
}

type TravelTypeContentRenderer = (props: {
  checked?: boolean;
}) => React.ReactNode;

const TabControlsContainer = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    alignItems: 'center',
    ml: [null, -3],
  })
);

const TravelTypeIcon = styled(Icon)(({ theme: { space } }) => ({
  marginRight: space[1],
}));

const TravelTypePlusDelimiter = styled.span(({ theme: { space } }) => ({
  padding: `0 ${space[2]}`,
}));

export const DesktopTravelTypeContent: React.FC<{ checked?: boolean }> = ({
  checked,
}) => {
  const [t] = useTranslation();

  return (
    <TabControls checked={checked}>
      <TabControlsContainer>
        <TravelTypeIcon name="flug" />
        {t('flight')}
        <TravelTypePlusDelimiter>+</TravelTypePlusDelimiter>
        <TravelTypeIcon name="hotel" />
        {t('hotel')}
      </TabControlsContainer>
    </TabControls>
  );
};

interface ITravelTypeRadioButton {
  travelType: TravelType;
  label: string;
  checked: boolean;
  onChange(value: TravelType): void;
  icon: string;
  testId?: string;
  renderTravelTypeContent?: TravelTypeContentRenderer | null;
}

const TravelTypeRadioButton: React.FC<ITravelTypeRadioButton> = ({
  travelType,
  label,
  checked,
  onChange,
  icon,
  testId,
  renderTravelTypeContent,
}) => {
  return (
    <RadioButton
      tabIndex={-1}
      hideLabel
      id={travelType}
      value={travelType}
      name={'travelTypes'}
      checked={checked}
      onChange={(value: string) => onChange(value as TravelType)}
      testId={testId}
      renderCustomControl={({ checked: nextChecked }) => {
        if (renderTravelTypeContent) {
          return renderTravelTypeContent({ checked: nextChecked });
        }
        return (
          <TabControls icon={icon} checked={nextChecked}>
            {label}
          </TabControls>
        );
      }}
    />
  );
};

interface ITravelTypeLink extends React.PropsWithChildren<{}> {
  link?: string;
  isExternal?: boolean;
  label: string;
  icon: string;
  testId?: string;
  onClick?: () => void;
}

const TravelTypeBaseLink = styled(Link)(({ theme: { colors } }) => ({
  width: '100%',
  marginBottom: '-1px',
  ':focus-visible': {
    '.tabControls': {
      outline: `auto 2px`,
      outlineOffset: '-1px',
      outlineColor: colors.defaultText,
    },
  },
}));

export const TravelTypeLink: React.FC<ITravelTypeLink> = ({
  link = '',
  isExternal = false,
  label,
  icon,
  testId,
  onClick,
  children,
}) => {
  return (
    <RouterLink href={link} isExternal={isExternal} passHref>
      <TravelTypeBaseLink
        className="travel-type-base-link"
        target="_blank"
        data-id={testId}
        onClick={onClick}
      >
        {children ? children : <TabControls icon={icon}>{label}</TabControls>}
      </TravelTypeBaseLink>
    </RouterLink>
  );
};

interface ITravelTypeButtonProps {
  onClick: () => void;
  checked?: boolean;
  text: string;
}

const TravelTypeMoreButton = styled.button.attrs({
  type: `button`,
})(
  sx2CssThemeFn({
    width: ['100%', 'auto'],
  })
);

const MoreButton: React.FC<ITravelTypeButtonProps> = ({
  onClick,
  checked,
  text,
}) => {
  return (
    <TravelTypeMoreButton onClick={onClick} data-id="travelTypesMoreButton">
      <TabControls icon="more" checked={checked}>
        {text}
      </TabControls>
    </TravelTypeMoreButton>
  );
};

interface ITravelTypeControlContainer {
  dropDownTravelTypes: (TravelType | ExtraTravelType)[];
  onSelectTravelType?: (travelType: TravelType | ExtraTravelType) => void;
}

const MoreButtonContainer = styled.li({
  position: 'relative',
});

const TravelTypeControlContainer: React.FC<ITravelTypeControlContainer> = ({
  dropDownTravelTypes,
  onSelectTravelType,
}) => {
  const [t, i18n] = useTranslation('common');
  const [isDropdownOpen, , closeDropdown, toggle] = useToggleState(false);
  const moreButtonContainerRef = useRef<HTMLLIElement>(null);
  useOnClickOutside<HTMLLIElement>(moreButtonContainerRef, closeDropdown);
  const { channelType } = useAuthentication();

  return (
    <MoreButtonContainer ref={moreButtonContainerRef}>
      <MoreButton onClick={toggle} checked={isDropdownOpen} text={t('more')} />
      {isDropdownOpen && (
        <DropDownMobileWrapper>
          {dropDownTravelTypes.map(travelType => {
            const travelTypeData = mapTravelTypeToTravelTypeData(
              travelType,
              channelType
            );
            return (
              travelTypeData && (
                <React.Fragment key={travelType}>
                  <TravelTypeLink
                    testId={travelTypeData.testId}
                    link={getLangSpecificLink(
                      i18n.language,
                      travelTypeData.link
                    )}
                    isExternal={travelTypeData.isExternal}
                    icon={travelTypeData.icon}
                    label={t(travelTypeData.label)}
                    onClick={() => onSelectTravelType?.(travelType)}
                  />
                </React.Fragment>
              )
            );
          })}
        </DropDownMobileWrapper>
      )}
    </MoreButtonContainer>
  );
};

interface ITravelTypeListProps {
  mainTravelTypes: (TravelType | ExtraTravelType)[];
  dropDownTravelTypes?: (TravelType | ExtraTravelType)[];
  activeType?: TravelType | null;
  travelTypeContent?:
    | {
        [key in TravelType]?: TravelTypeContentRenderer;
      }
    | null;
  setActiveType(nextValue: TravelType | null): void;
  onSelectTravelType?(travelType: TravelType | ExtraTravelType): void;
}

const TravelTypeList = styled.ul(
  sx2CssThemeFn({
    display: 'flex',
    boxShadow: 'inset 0 -1px 0 0 #ECECED',
  })
);

const TravelTypeItem = styled.li(({ theme: { colors } }) =>
  sx2CssThemeFn({
    margin: 0,
    boxSizing: 'border-box',
    flex: ['1 0 auto', '1 1 auto'],
    lineHeight: '1',
    '&:focus-visible': {
      '.tabControls': {
        outline: `auto 2px`,
        outlineOffset: '-1px',
        outlineColor: colors.defaultText,
      },
    },
    '&:hover': {
      '.tabControls': {
        color: colors.interactionPrimary,
      },
      '.checked': {
        color: colors.primary,
      },
    },
  })
);

const TravelTypeControls: React.FC<ITravelTypeListProps> = ({
  mainTravelTypes,
  dropDownTravelTypes = [],
  activeType,
  setActiveType,
  travelTypeContent,
  onSelectTravelType,
}) => {
  const { t, i18n } = useTranslation('common');
  const { channelType } = useAuthentication();

  return (
    <TravelTypeList role="tablist" className="travelTypeList">
      {mainTravelTypes.map(travelType => {
        const travelTypeData = mapTravelTypeToTravelTypeData(
          travelType,
          channelType
        );
        const travelTypeRenderer = travelTypeContent
          ? travelTypeContent[travelType]
          : null;

        return (
          <TravelTypeItem
            key={travelType}
            className="travelTypeItem"
            role="presentation"
            tabIndex={!travelTypeData.link ? 0 : -1}
            onKeyPress={e => {
              if (e.key === ENTER_KEY && !travelTypeData.link) {
                setActiveType(travelType as TravelType);
                onSelectTravelType?.(travelType);
              }
            }}
          >
            {travelTypeData.link ? (
              <TravelTypeLink
                link={getLangSpecificLink(i18n.language, travelTypeData.link)}
                isExternal={travelTypeData.isExternal}
                icon={travelTypeData.icon}
                label={t(travelTypeData.label)}
                testId={travelTypeData.testId}
                onClick={() => onSelectTravelType?.(travelType)}
              />
            ) : (
              <TravelTypeRadioButton
                travelType={travelType as TravelType}
                label={t(travelTypeData.label)}
                checked={activeType === travelType}
                onChange={(value: TravelType) => {
                  setActiveType(value);
                  onSelectTravelType?.(value);
                }}
                icon={travelTypeData.icon}
                testId={travelTypeData.testId}
                renderTravelTypeContent={travelTypeRenderer}
              />
            )}
          </TravelTypeItem>
        );
      })}
      {dropDownTravelTypes.length !== 0 && (
        <TravelTypeControlContainer
          dropDownTravelTypes={dropDownTravelTypes}
          onSelectTravelType={onSelectTravelType}
        />
      )}
    </TravelTypeList>
  );
};

export default TravelTypeControls;
